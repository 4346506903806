function getEnvVariable(key: string): string {
  const value = import.meta.env[key];
  if (!value) {
    throw new Error(`Environment variable ${key} is not defined.`);
  }
  return value;
}

export const Env = {
  baseUrl: getEnvVariable('VITE_API_BASE_URL')
}