import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const iconBtns: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "58px",
  height: "58px",
  position: "relative",
  borderRadius: "8px",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: "1",
  margin: "-5px",
  padding: "5px",
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    width: "58px",
    height: "58px",
    backgroundImage: "var(--btn-gradient)",
    opacity: 0,
    transition: "all 0.3s ease",
    zIndex: -1,
    borderRadius: "8px",
    transform: "scale(0.85)",
  },

  "&:hover::before, &:active::before": {
    transition: "all 0.3s ease",
    opacity: 1,
    zIndex: -1,
    transform: "scale(1)",
  },
}

export const userAvatar: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "58px",
  height: "58px",
  position: "relative",
  borderRadius: "8px",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: "1",
  margin: "-5px",
  padding: "5px",
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    width: "58px",
    height: "58px",
    backgroundImage: "var(--btn-gradient)",
    opacity: 0,
    transition: "all 0.3s ease",
    zIndex: -1,
    borderRadius: "8px",
    transform: "scale(0.85)",
  },

  "&:hover::before, &:active::before": {
    transition: "all 0.3s ease",
    opacity: 1,
    zIndex: -1,
    transform: "scale(1)",
  },
}
