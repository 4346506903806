import { useGetLocationQuery } from "../../../app/data/location/locationApi"

export const useGetCityFromGeolocation = () => {
  const { data, error, isLoading } = useGetLocationQuery()

  const getCity = () =>
    isLoading || !data || !data.city
      ? Promise.reject(error)
      : Promise.resolve(data.city)

  return { getCity }
}
