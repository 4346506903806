import { FC } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { Participant } from "../../../../shared/events-feed/slice/types";
import { avatarUrlDecorator } from "../../../../app/data/avatarUrl";
import {  cardAvatarIcons } from "./styles";

type  Participants =  Participant[]

interface ParticipantsAvatarRowProps {
  participants: Participants
  onClick?: (participants: Participants)=>void
  maxCount: number
}
function truncateArray<T>(array: T[], maxLength: number): T[] {
  return array.length > maxLength ? array.slice(0, maxLength) : array
}


const ParticipantsAvatarRow: FC<ParticipantsAvatarRowProps> = ({participants, onClick, maxCount}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(participants)
    }
  }
  if (!participants) return null
  if (participants.length ===  0){
    return ( <Typography variant="h6" color={`var(--secondary-text)`}>
      Нет участников
    </Typography>)
  }
  return <>
    <Box onClick={handleClick} sx={{
      display: "flex",
      gap: "8px",
      width: "100%",
      alignItems: "center",
      mb: "4px",
    }}>
      <Stack
        direction="row"
        spacing={-1}
        sx={{
          div: { border: "solid 2px var(--menu-header-bg) !important" },
          fontSize: "1px",
          "&:hover ":{
            cursor: "pointer"
        }
        }}
      >
        {truncateArray(participants, maxCount ?? 5).map((user: Participant) => {
          return (
            <Avatar
              sx={cardAvatarIcons}
              key={user.participant_id}
              alt={user.participant_name ?? "Гость"}
              src={avatarUrlDecorator(user.participant_avatar_url)}
            />
          )
        })}
      </Stack >
      <Typography variant="body2" color={`var(--secondary-text)`}>
        {participants.length > 100 && `+100 уже идут`}
        {participants.length> 1000 && `+${"1000".toString()[0]}k уже идут`}
      </Typography>
    </Box>
  </>
}

export default ParticipantsAvatarRow