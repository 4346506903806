import { FC } from "react";
import { EventDto } from "@shared/events-feed/slice/types";
import FeedCard from "../StyledCard/FeedCard";
import PrivateFeedCard from "@components/StyledCard/PrivateFeedCard";

export interface CardLayoutProps {
  events: EventDto[]
}
const StyledCardLayout:FC<CardLayoutProps> = ({events}) => {
  return <>
    {events.map(event => {
      if (event.isPrivate){
        return  <PrivateFeedCard eventInfo={event}  key={event.eventId} />
      }
      return <FeedCard eventInfo={event} key={event.eventId} />
    })}
  </>
}
export  default StyledCardLayout;