import { Avatar, AvatarGroup, Box, Typography } from "@mui/material"
import { cardAvatarCont } from "../CardEvent/CardEventStyles"
import { UsersWhoParticipantsOfEventDto } from "../../../app/data/tribeSwaggerApi"
import { avatarUrlDecorator } from "../../../app/data/avatarUrl"
import { cardAvatarIcons } from "../CardAvatar/cardAvatarStyles"

type Props = {
  avatars: UsersWhoParticipantsOfEventDto[]
}

export default function CardAvatarRow({ avatars }: Props) {
  if (avatars.length === 0) return (<Typography>
    Нет участников
  </Typography>)
  const quantity = avatars.length
  return (
    <Box sx={cardAvatarCont}>
      <AvatarGroup
        max={5}
        sx={{
          div: { border: "solid 2px var(--menu-header-bg) !important" },
        }}
      >
        {avatars.map((user) => {
          return (
            <Avatar
              sx={cardAvatarIcons}
              key={user.participant_id}
              alt="Гость"
              src={avatarUrlDecorator(user.participant_avatar_url)}
            />
          )
        })}
      </AvatarGroup>
      <Typography variant="body2" color={`var(--secondary-text)`}>
        {quantity > 100 && `+100 уже идут`}
        {quantity > 1000 && `+${"1000".toString()[0]}k уже идут`}
      </Typography>
    </Box>
  )
}
