import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import baseQuery from "@app/data/jwt/baseRequest";
import { userLogout } from "@app/data/appTokensStore";
import { refreshTokens } from "@app/data/jwt/refreshTokens";

export const refreshBaseQuery: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError
> = async (args, api, extra) => {
  // Выполняем исходный запрос
  let result = await baseQuery(args, api, extra);
  if (result.error?.status === 401) {
    const newTokens = await refreshTokens(api, extra);
    if (newTokens) {
      result = await baseQuery(args, api, extra);
      if (result.error) {
        console.error('Retry request failed:', result.error);
        api.dispatch(userLogout());
      }
    }else {
      console.error('Refresh tokens failed');
      api.dispatch(userLogout());
      result = await baseQuery(args, api, extra);
    }
  }

  return result;
};
