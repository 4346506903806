const formatDateTime = (dateString: string): { date: string; time: string } | null => {
  const date = new Date(dateString);

  // Проверяем, корректна ли дата
  if (isNaN(date.getTime())) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const formattedDate = dateFormatter.format(date); // Формат: "28 ноября 2023"
  const formattedTime = timeFormatter.format(date); // Формат: "20:00"

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export default formatDateTime
