import { RemoteConfigSchema } from "@utils/useRemoteConfig/config";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import {remoteConfig} from "@/App"
import { useEffect, useState } from "react";
const useRemoteConfig = (): RemoteConfigSchema => {
  const [config, setConfig] = useState<RemoteConfigSchema>({isNativeSharedDialog: false})
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig)
        const isNativeSharedDialog =  getValue(remoteConfig, "isNativeSharedDialog").asBoolean()
        setConfig({isNativeSharedDialog})
      }catch (error){
        console.error("Error fetching remote config:",error)
      }
    }
    fetchConfig()
  }, []);
  return  config
}

export default useRemoteConfig