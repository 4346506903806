import { useEffect, useState } from "react"
import { Box, Avatar } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { useGetUserProfileQuery } from "../../../../app/data/userDataSlice/userApi"
import { avatarUrlDecorator } from "../../../../app/data/avatarUrl"
import { selectUserId } from "../../../../app/data/appTokensStore"
import { useIsAuthorizedUser } from "../../../../hooks/useIsAuthorizedUser"

import { show } from "../../../../pages/signIn/SignInSlice"
import HeaderIconBtn from "../HeaderIconsWrapper"
import { HeaderBtnName } from "../HeaderIconsWrapper/enum"
import HeaderProfileMenu from "../HeaderProfileMenu"
import AvatarFallback from "/src/assets/svg/header-icons-pack/avatar-empty-icon.svg"
import { userAvatar } from "./headerIconsStyle"

export const HeaderIcons = () => {
  const isUserAuth = useIsAuthorizedUser()
  const dispatch = useAppDispatch()

  const [activeBtn, setActiveBtn] = useState<HeaderBtnName | null>(null)
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const ls = localStorage.getItem("LocalCurrentUser")
  const userFromLS = ls && JSON.parse(atob(ls.split(".")[1]))
  const isOrganizerRole = userFromLS?.role === "ORGANIZATION_ADMIN"

  const { data: userData } = useGetUserProfileQuery(
    { userId: useAppSelector(selectUserId) ?? 0 },
    { skip: !useAppSelector(selectUserId) },
  )

  const handleProfileMenuOpen = (): void => {
    if (!isUserAuth) {
      dispatch(show())
    } else {
      setIsOpenMenu(!isOpenMenu)
      setActiveBtn(null)
    }
  }

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = "hidden"
      document.body.style.height = "100vh"
    } else {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
    }
  }, [isOpenMenu])

  return (
    <Box onClick={handleProfileMenuOpen}>
      {isUserAuth ? (
        <Avatar
          src={avatarUrlDecorator(userData?.avatar_url)}
          alt="profile avatar"
          sx={userAvatar}
        >
          <img src={AvatarFallback} alt="profile icon" />
        </Avatar>
      ) : (
        <HeaderIconBtn
          icon={<img src={AvatarFallback} alt="profile icon" />}
          setActiveBtn={setActiveBtn}
          activeBtn={activeBtn}
          title={HeaderBtnName.profile}
        />
      )}

      <HeaderProfileMenu
        isOpenMenu={isOpenMenu}
        isOrganizerRole={isOrganizerRole}
      />
    </Box>
  )
}
