import "./App.css";
import { AppRouters } from "./routes/Routes";
import { FC, useEffect } from "react";

import {initializeApp} from 'firebase/app';
import { getRemoteConfig } from "firebase/remote-config";
import {getAnalytics, logEvent} from 'firebase/analytics';
import firebaseConfig from "./config/firebaseConfig";
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app)
remoteConfig.settings = {
  fetchTimeoutMillis: 6000, minimumFetchIntervalMillis: 6000
}
remoteConfig.defaultConfig = {
  isNativeSharedDialog: false
}

const App: FC = () => {
  useEffect(() => {
    try {
      logEvent(analytics, 'root_page_view');

    }catch (e) {
      console.error(e)
    }
  }, []);
  return (<AppRouters />);

};
export default App;
