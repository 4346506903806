import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import createDefaultState from "./defaultState";
import { EventDto } from "./types";

const index = createSlice({
  name: "events",
  initialState: createDefaultState(),
  reducers: {
    setEventList: (state, action: PayloadAction<EventDto[]>) => {
        state.events = action.payload;
    }
  }
})
export const { setEventList } = index.actions
export default index.reducer