import { swaggerSplitApi as api } from "../swaggerApi"
import { TypeCategories } from "./types";
import { setCategories } from "@shared/categories";

const categoryApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    getAllCategories: build.query<TypeCategories, void>({
      query(){
        return "/api/v1/event/type/info"
      },
      async onQueryStarted(args, {dispatch, queryFulfilled}){
        const {data} = await queryFulfilled
        const categories = data.map((item) => ({
            id: item.id,
            title: item.type_name,
            priority: item.priority,
            url: item.image_url.x1,
            srcset:
              item.image_url.x1 +
              " 1x, " +
              item.image_url.x2 +
              " 2x, " +
              item.image_url.x3 +
              " 3x, " +
              item.image_url.x4 +
              " 4x",
          })).sort((a, b) => a.priority - b.priority)
          dispatch(setCategories(categories))

      }
    })
  })
})

export const {useGetAllCategoriesQuery} = categoryApi