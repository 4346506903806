import eventPhoto from "../../app/data/avatarUrl"
import * as api from "../../app/data/tribeSwaggerApi"
import { SearchEventDto } from "../../app/data/tribeSwaggerApi"
import { EventModel } from "../../types"

export function mapDtoToModel(dto: SearchEventDto): EventModel {
  const avatar = dto?.avatarUrl?.at(0)
  return {
    calendar: dto.startTime ?? "",
    description: dto.description ?? "",
    id: dto?.eventId!!,
    img: eventPhoto(avatar),
    location: dto.eventAddress?.city ?? "",
    time: dto.startTime ?? "",
    title: dto.eventName ?? "",
    isAlcohol: dto.isPresenceOfAlcohol,
    isFavorite: !!dto.favoriteEvent,
    coordinates: [0],
  }
}

export function mapProfileEventDtoToModel(
  dto: api.EventInUserProfileDto,
): EventModel {
  const avatar = dto?.event_photo_url?.at(0)
  return {
    calendar: dto.start_time ?? "",
    description: dto.event_name ?? "",
    id: dto?.id!!,
    img: eventPhoto(avatar),
    location: dto.city ?? "",
    time: dto.start_time ?? "",
    title: dto.event_name ?? "",
    coordinates: [0],
    isFavorite: false,
  }
}

