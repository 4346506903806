import { useState, useEffect } from "react"
import {
  useGetUserInfoWritableQuery,
  useUpdateUserSettingsMutation,
} from "../../../app/data/userDataSlice/userApi"
import { ResponseStatus } from "../../../shared/user-profile/types"
import {
  UserInfoWritable,
  UserProfileUpdate,
} from "../../../app/data/userDataSlice/types"
import { IoError, ioErrorOf } from "../../../app/data/errors"
import { useAppSelector } from "../../../app/hooks"
import { selectUserId } from "../../../app/data/appTokensStore"

export type UpdateProfileArgs = Omit<UserProfileUpdate, "user_id">
export type PathProfile = {
  patch: (value: UpdateProfileArgs) => void
  isLoading: boolean
  error?: IoError
}

export type SettingsEditor = {
  updateProfile: (nextState: Partial<UserProfileUpdate>) => void
  isLoading: boolean
  error?: IoError
  currentUser?: UserInfoWritable
}

const createInitialProfileState = (id?: number): UserProfileUpdate => ({
  interesting_event_type: [],
  user_id: id,
  username: "",
})

export const useProfileSettings = (): ResponseStatus<UserInfoWritable> => {
  const userId = useAppSelector(selectUserId)!
  const { data, error, isLoading } = useGetUserInfoWritableQuery({ userId })

  return { isLoading, error: ioErrorOf(error), state: data }
}

export const useUpdateProfileSettings = (): PathProfile => {
  const [updateUserProfile, { isLoading, error }] =
    useUpdateUserSettingsMutation()
  const patchProfile = (value: UpdateProfileArgs) => {
    updateUserProfile({ user_id: useAppSelector(selectUserId)!, ...value })
  }

  return {
    isLoading,
    error: ioErrorOf(error),
    patch: patchProfile,
  }
}

export const useInterestsSettingsEditor = (): SettingsEditor => {
  const userId = useAppSelector(selectUserId)!
  const { data, error, isLoading } = useGetUserInfoWritableQuery({ userId })
  const [profileState, setProfileState] = useState<UserProfileUpdate>(
    createInitialProfileState(Number(userId)),
  )
  const [updateProfile] = useUpdateUserSettingsMutation()

  useEffect(() => {
    if (data) {
      setProfileState((prevState) => ({
        ...prevState,
        username: data.username,
        interesting_event_type: data.interesting_event_type.map(
          (event) => event.id,
        ),
      }))
    }
  }, [data])

  const handleUpdateProfile = (nextState: Partial<UserProfileUpdate>) =>
    updateProfile({ ...profileState, ...nextState })

  return {
    updateProfile: handleUpdateProfile,
    isLoading,
    error: ioErrorOf(error),
    currentUser: data,
  }
}
