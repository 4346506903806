import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"

import Paper from "@mui/material/Paper"
import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import Slide from "@mui/material/Slide"

import { useAppDispatch } from "../../app/hooks"
import { useIsAuthorizedUser } from "../../hooks/useIsAuthorizedUser"
import { show } from "../../pages/signIn/SignInSlice"
import { show as showEvent } from "../../pages/makeEvent/slice/MakeEventSlice"

import { footerCont, footerNav, footerNavItem } from "./footerStyle"

import CreateIcon from "@mui/icons-material/AddRounded"
import UserIcon from "@mui/icons-material/PermIdentityRounded"
import SearchIcon from "@mui/icons-material/SearchRounded"
import FavoriteIcon from "@mui/icons-material/FavoriteBorderRounded"
import ChatIcon from "@mui/icons-material/TextsmsOutlined"
import HomeIcon from "@mui/icons-material/CottageOutlined"

const Footer: FC = () => {
  const trigger = useScrollTrigger()
  const isAuth = useIsAuthorizedUser()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [selectedValue, setSelectedValue] = useState("")

  const handleNavigationChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setSelectedValue(newValue)

    if (newValue !== "/" && !isAuth) dispatch(show())
    else if (newValue === "/create") dispatch(showEvent())
    else navigate(newValue)
  }

  const navigationLinks = [
    //Раскоммитить когда будут готовы соотв. разделы.
    //Должно быть нечетное кол-во и кнопка создать в центре, профилем можно пожертвовать
    //Не забыть подправить стили строки 45, 53 и 57
    //{ to: "/search", icon: SearchIcon },
    { to: "/profile", icon: UserIcon },
    //{ to: "/favorite", icon: FavoriteIcon },
    { to: "/create", icon: CreateIcon },
    //{ to: "/chat", icon: ChatIcon },
    { to: "/", icon: HomeIcon },
  ]

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      <Paper sx={footerCont} elevation={3}>
        <BottomNavigation
          sx={footerNav}
          showLabels={false}
          value={selectedValue}
          onChange={handleNavigationChange}
        >
          {navigationLinks.map(({ to, icon: Icon }, index) => (
            <BottomNavigationAction
              key={index}
              sx={footerNavItem}
              value={to}
              icon={<Icon />}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Slide>
  )
}

export default Footer
