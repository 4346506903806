import { useState, useEffect, FC } from "react"
import {
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material"

import { interests as interestOptions } from "../../../consts"
import { useInterestsSettingsEditor } from "../hooks/useInterestsSettings"
import GradientBtn from "../../../components/GradientBtn"

import {
  interestsList,
  interestsListItem,
  interestsListButton,
} from "./interestsSettingsStyle"
import s from "./styles.module.scss"

const InterestsSettings: FC = () => {
  const [selectedInterestIds, setSelectedInterestIds] = useState<Set<number>>(
    new Set(),
  )

  const { updateProfile, currentUser } = useInterestsSettingsEditor()

  useEffect(() => {
    if (currentUser) {
      setSelectedInterestIds(
        new Set(currentUser.interesting_event_type.map((item) => item.id)),
      )
    }
  }, [currentUser])

  const handleToggleAll = (): void => {
    setSelectedInterestIds(
      (prev) =>
        new Set(
          prev.size === interestOptions.length
            ? []
            : interestOptions.map((item) => item.id),
        ),
    )
  }

  const handleToggleInterest = (id: number): void => {
    setSelectedInterestIds(
      (prev) =>
        new Set(
          prev.has(id) ? [...prev].filter((v) => v !== id) : [...prev, id],
        ),
    )
  }

  const handleSave = (): void => {
    updateProfile({
      interesting_event_type: Array.from(selectedInterestIds),
    })
  }

  const renderInterestsList = (): JSX.Element[] => {
    return interestOptions.map((option) => (
      <ListItem key={option.id} sx={interestsListItem} disablePadding>
        <ListItemButton
          sx={interestsListButton}
          role={undefined}
          onClick={() => handleToggleInterest(option.id)}
          dense
          disableGutters
        >
          <ListItemText
            id={option.id.toString()}
            primary={option.text}
            sx={{ margin: 0, span: { fontWeight: 500 } }}
          />
          <ListItemIcon>
            <Checkbox
              sx={{
                margin: 0,
                padding: 0,
                right: 0,
                color: "var(--secondary-text)",
                "&.Mui-checked": {
                  color: "var(--filter-range-active)",
                },
              }}
              edge="start"
              checked={selectedInterestIds.has(option.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": option.text }}
            />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    ))
  }

  return (
    <div className={s.interests_container}>
      <List sx={interestsList}>
        <ListItem sx={interestsListItem} disablePadding>
          <ListItemButton
            sx={interestsListButton}
            role={undefined}
            onClick={handleToggleAll}
            dense
            disableGutters
          >
            <ListItemText
              id="select-all"
              primary="Все"
              sx={{ margin: 0, span: { fontWeight: 500 } }}
            />
            <ListItemIcon>
              <Checkbox
                sx={{
                  margin: 0,
                  padding: 0,
                  right: 0,
                  color: "var(--secondary-text)",
                  "&.Mui-checked": {
                    color: "var(--filter-range-active)",
                  },
                }}
                edge="start"
                checked={selectedInterestIds.size === interestOptions.length}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "select-all" }}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {renderInterestsList()}
      </List>

      <GradientBtn
        type="submit"
        title="Сохранить изменения"
        aria-label="save changes"
        textStyle={{ fontSize: "12px", textTransform: "uppercase" }}
        btnStyle={{
          width: "300px",
          maxWidth: "100%",
          height: "36px",
          padding: "10px 8px",
          fontSize: "12px",
        }}
        handleAction={handleSave}
      />
    </div>
  )
}
export default InterestsSettings
