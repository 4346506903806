import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface InfoLabelProps {
  text?: string | null
  LeftIcon:  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}
const InfoLabel: FC<InfoLabelProps> = ({text, LeftIcon}) => {
  if (!text || text.length === 0) return null
  return <Box sx={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    width: "fit-content",
    marginBottom: "4px"
  }}>
    <LeftIcon style={{
      marginRight: "4px"
    }} stroke={"var(--secondary-text)"}/>
    <Typography sx={{
      textWrap: "nowrap"
    }} variant="body2" color={`var(--secondary-text)`}>
      {text}
    </Typography>
  </Box>
}

export default InfoLabel