import { useState, useEffect } from "react";

// Типы экранов
export type ScreenSize = "mobile" | "tablet" | "desktop";

// Тип брейкпоинтов
export type Breakpoints = {
  mobile: number; // Минимальная ширина для "mobile"
  tablet: number; // Минимальная ширина для "tablet"
  desktop: number; // Минимальная ширина для "desktop"
};

// Хук с кастомными брейкпоинтами
const useScreenSize = (customBreakpoints?: Breakpoints): ScreenSize => {
  // Значения по умолчанию
  const defaultBreakpoints: Breakpoints = {
    mobile: 0, // от 0 до 767px
    tablet: 768, // от 768 до 1023px
    desktop: 1024, // от 1024px и выше
  };

  // Используем кастомные брейкпоинты, если они переданы
  const breakpoints = customBreakpoints || defaultBreakpoints;

  // Определяем текущий размер экрана
  const getScreenSize = (): ScreenSize => {
    const width = window.innerWidth;
    if (width >= breakpoints.desktop) return "desktop";
    if (width >= breakpoints.tablet) return "tablet";
    return "mobile";
  };

  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener("resize", handleResize);

    // Убираем обработчик при размонтировании
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoints]); // Завиcимость от брейкпоинтов

  return screenSize;
};
export default useScreenSize