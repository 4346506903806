import { FC } from "react";
import { Typography } from "@mui/material";

export interface DialogTitleProps {
  text: string
}
const DialogTitleStyled: FC<DialogTitleProps> = ({text}) => {
  return <Typography
      align="center"
      color="var(--secondary-text)"
      textAlign="center"
      fontFamily="Roboto"
      fontSize="20px"
      lineHeight="24px"
      fontWeight="800"
      component="div">{text}</Typography>
}
export default DialogTitleStyled