import { FC } from "react";
import { useAppDispatch } from "@app/hooks";
import { useIsAuthorizedUser } from "@hooks/useIsAuthorizedUser";
import { newEvent } from "@pages/fid/fidStyles";
import GradientBtn from "@components/GradientBtn";
import { show } from "@pages/signIn/SignInSlice";
import { show as showEvent } from "@pages/makeEvent/slice/MakeEventSlice";

const createEventButton: FC = () => {
  const dispatch = useAppDispatch();
  const isAuth = useIsAuthorizedUser()
  const onCreateEvent = () => {
    if (!isAuth) {
      dispatch(show());
    } else {
      dispatch(showEvent());
    }
  }
  return <GradientBtn handleAction={onCreateEvent} title="+ Создать событие" btnStyle={newEvent}  />
}
export default createEventButton