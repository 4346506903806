import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"
import { CSSProperties } from "react"

export enum Visibility {
  visible = "visible",
  hidden = "hidden",
}

//#region HEADER
export const headerCont = {
  display: "flex",
  width: "100%",
  backgroundColor: "var(--header)",
  minHeight: "72px",
  padding: "12px 8px",
  "@media (min-width: 638px)": {
    padding: "12px 32px",
  },
}

export const headerStyle: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "minmax(48px, auto) 1fr",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0 auto",
  width: "100%",
  maxWidth: "2000px",
  gap: "8px",
  padding: "0 !important",
  minHeight: "auto !important",
}

export const headerLeftCont: SxProps<Theme> = {
  display: "inline-flex",
  gap: "8px",
}

export const headerRightCont: SxProps<Theme> = {
  display: "flex",
  gap: "8px",
  justifyContent: "end",
  width: "100%",
}
//#endregion

export const logoLink: CSSProperties = {
  display: "flex",
  alignItems: "center",
  height: "48px",
  cursor: "pointer",
}

//#region styles GEO
export const geolocationStyle: CSSProperties = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "8px",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  color: "var(--header-text)",
  cursor: "pointer",
  overflowWrap: "anywhere",
}
//#endregion

//#region VisibleOrNoFunction
export const handleVisiblity = (
  v: "open" | "close" | "delete" | "filter-off" | "filter-on",
  isOpenMenu: boolean,
): CSSProperties | undefined => {
  const ifTrueHide = {
    opacity: isOpenMenu ? 0 : 1,
    visibility: isOpenMenu ? Visibility.hidden : Visibility.visible,
    transition: "all 0.3s ease",
  }

  const ifTrueShow = {
    opacity: isOpenMenu ? 1 : 0,
    visibility: isOpenMenu ? Visibility.visible : Visibility.hidden,
    transition: "all 0.3s ease",
  }

  switch (v) {
    case "open":
      return { ...ifTrueHide, position: "absolute" }
    case "close":
      return { ...ifTrueShow, position: "absolute" }
    case "delete":
      return { ...ifTrueShow, cursor: isOpenMenu ? "pointer" : "none" }
    case "filter-off":
      return {
        ...ifTrueHide,
        position: "absolute",
        right: "-6",
        top: "-6",
        transform: "rotate(90deg)",
      }
    case "filter-on":
      return {
        ...ifTrueShow,
        position: "absolute",
        right: "12",
        top: "12",
        transform: "rotate(90deg)",
      }
    default:
      break
  }
}
//#endregion
