import { useResizeComputed } from "@hooks/useResize";
import { FeedPaginationState } from "./types";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { selectCurrentPage } from "@shared/feed-pagination/selectors";
import { setPage } from "@shared/feed-pagination/paginationSlice";

const useFeedPaginationState = (): FeedPaginationState => {
  const dispatch = useAppDispatch()
  const currentPage = useAppSelector(selectCurrentPage)
  const quantityCardsPerPage = useResizeComputed<12 | 10>((size) => {
    return size > 1365 ? 12 : 10
  })
  const setPageHandler = (newPage: number) => {
    dispatch(setPage(newPage))
  }
  return {
    page: currentPage, size: quantityCardsPerPage,
    setPage: setPageHandler
  }
}

export default useFeedPaginationState;