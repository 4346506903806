import { FC, useState } from "react";
import { EventDto } from "@shared/events-feed/slice/types";
import formatDateTime from "@components/StyledCard/components/formatDateTime";
import { FeedCardContent, StyledCard, StyledCardMedia } from "@components/StyledCard/styles/Card";
import { Link } from "react-router-dom";
import FallbackImg from "@assets/svg/profile/logo-icon.svg";
import CardBadge from "@components/StyledCard/components/CardBadge";
import { Row } from "@/components";
import CardTitle from "@components/StyledCard/components/CardTitle";
import InfoLabel from "@components/StyledCard/components/InfoLabel";
import addressFormat from "@components/StyledCard/components/addressFormat";
import CardDivider from "@components/StyledCard/components/CardDivider";
import CardDescription from "@components/StyledCard/components/CardDescription";
import { CardActions } from "@mui/material";
import CalendarIconCard from "/src/assets/svg/card-icons-pack/calendar-icon.svg?react";
import ClockIconCard from "/src/assets/svg/card-icons-pack/clock-icon.svg?react";
import LocationIconCard from "/src/assets/svg/card-icons-pack/location-icon.svg?react";
export interface PrivateFeedCardProps {
  eventInfo: EventDto
}
const PrivateFeedCard: FC<PrivateFeedCardProps> = ({ eventInfo}) => {
  const formatTime = formatDateTime(eventInfo.startTime);



  return (<StyledCard>
    <Link to={`fid/${eventInfo.eventId}`}>
      <StyledCardMedia
        image={eventInfo.avatarUrl[0] ?? FallbackImg}
        title={eventInfo.eventName}
      >
        <CardBadge time={eventInfo?.startTime} />
      </StyledCardMedia>
    </Link>
    <FeedCardContent>
      <Row>
        <Link to={`fid/${eventInfo.eventId}`}>
          <CardTitle title={eventInfo.eventName} />
        </Link>
      </Row>
      <InfoLabel
        text={addressFormat(eventInfo.eventAddress)}
        LeftIcon={LocationIconCard}
      />
      <Row justifyContent="start" gap={14}>
        <InfoLabel text={formatTime?.date} LeftIcon={CalendarIconCard} />
        <InfoLabel text={formatTime?.time} LeftIcon={ClockIconCard} />
      </Row>
      <CardDivider />
      <Link to={`fid/${eventInfo.eventId}`}>
        <CardDescription text={eventInfo.description} />
      </Link>
    </FeedCardContent>
    <CardActions>
    </CardActions>
  </StyledCard>);
}
export default PrivateFeedCard