import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/svg/filter-pack/search-icon.svg?react";

import s from "../styles.module.css";
import { useRegionAutocomplete } from "../../../shared/autocomplete-location/useRegionAutocomplete";
import { AddressLocation } from "../../../app/data/location/schema";

const FilterByCity = () => {
  const  [currentCity, setCurrentCity] = useState<string>("")
  const {
    cityList,
    search
  } = useRegionAutocomplete();
  const onChange = (_: any, newValue:string | null ) => {
    if (!newValue) return
  }
  const onInputChange = (_: React.SyntheticEvent, value: string) => {
    setCurrentCity(value)
    search(value)
  }
  return (
    <div className={s.category_box}>
      <h4 className={s.title}>Где искать:</h4>

      <div className={s.box}>
        <Autocomplete
          freeSolo
          autoHighlight={false}
          onChange={onChange}
          inputValue={currentCity}
          onInputChange={onInputChange}
          id="autocomplete-field"
          options={cityList?.map(city=>city.city) ?? []}
          renderOption={(props, option) => (
            <Box component="div" key={option}>
              <li {...props}>{option}</li>
              <Divider />
            </Box>
          )}
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                border: "none",
                borderRadius: "8px",
                mt: 1,
                bgcolor: "var(--main-bg)",
                color: `${localStorage.getItem("theme") === "dark" ? 'white' : 'auto'}`,

                "& .MuiAutocomplete-option": {
                  p: "16px !important",
                  lineHeight: "20px !important",

                  "&:hover": {
                    bgcolor: "var(--inputs-bg) !important"
                  }
                }
              }}
            >
              {children}
            </Paper>
          )}
          sx={{
            width: "100%",

            "& .MuiOutlinedInput-root": {
              border: "none",
              p: 0,
              pl: 1
            }
          }}
          renderInput={(params) => (
            <TextField
              placeholder="Город, радиус."
              autoFocus={false}
              focused={false}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon stroke="var(--secondary-text)" />
              }}
              sx={{
                outline: "none",
                border: "none",
                borderRadius: "8px",
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                letterSpacing: "1px",
                background: "var(--inputs-bg)",

                "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                  color: "var(--secondary-text)",
                  p: "6px"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                },

                ".MuiAutocomplete-clearIndicator": {
                  color: "var(--secondary-text)"
                }
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default FilterByCity;
