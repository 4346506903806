export const getDayAndMonth = (dateString: string): [number, string] | null => {
  const date = new Date(dateString);

  // Проверяем, корректна ли дата
  if (isNaN(date.getTime())) {
    return null;
  }

  const day = date.getDate(); // Число дня месяца
  const month = date
    .toLocaleString(undefined, { month: 'short' })
    .slice(0, 4)
    .toUpperCase(); // Первые 4 буквы месяца в верхнем регистре

  return [day, month];
};
