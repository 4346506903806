import { FC, PropsWithChildren, ReactNode } from "react"
import { Navigate } from "react-router-dom"
import { useAppSelector } from "@app/hooks"

export type PrivateRouteProps = PropsWithChildren<{
  redirect: string
}>

export const ProtectedRoutePromoterCashier: FC<PrivateRouteProps> = ({
  children,
  redirect,
}): ReactNode => {
  const isPromoter = useAppSelector(
    (state) => state.userProfileState.info.isPromoter_or_Cashier,
  )

  return isPromoter ? children : <Navigate to={redirect} />
}
