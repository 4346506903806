import { FC, } from "react";
import { EventDto } from "../../../shared/events-feed/slice/types";
import FallbackImg from "../../../assets/svg/profile/logo-icon.svg";
import CalendarIconCard from "/src/assets/svg/card-icons-pack/calendar-icon.svg?react";
import ClockIconCard from "/src/assets/svg/card-icons-pack/clock-icon.svg?react";
import LocationIconCard from "/src/assets/svg/card-icons-pack/location-icon.svg?react";
import InfoLabel from "../components/InfoLabel";
import CardTitle from "../components/CardTitle";
import { Row } from "../../index";

import { FeedCardContent, StyledCard, StyledCardMedia } from "../styles/Card";
import formatDateTime from "../components/formatDateTime";
import addressFormat from "../components/addressFormat";


export type FeedCardProps = {
  eventInfo: EventDto
}
const ModalCard: FC<FeedCardProps> = ({ eventInfo }) => {
  const formatTime = formatDateTime(eventInfo.startTime);

  return (<StyledCard>

      <StyledCardMedia
        image={eventInfo.avatarUrl[0] ?? FallbackImg}
        title={eventInfo.eventName}
      >
      </StyledCardMedia>

    <FeedCardContent>
      <Row>
        <CardTitle  title={eventInfo.eventName} />
      </Row>
      <InfoLabel
        text={addressFormat(eventInfo.eventAddress)}
        LeftIcon={LocationIconCard}
      />
      <Row justifyContent="start" gap={14}>
        <InfoLabel text={formatTime?.date} LeftIcon={CalendarIconCard} />
        <InfoLabel text={formatTime?.time} LeftIcon={ClockIconCard} />
      </Row>
    </FeedCardContent>
  </StyledCard>);
};
export default ModalCard;