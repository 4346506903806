import { EventAddress } from "../../../../shared/events-feed/slice/types";

const addressFormat = (address?:  EventAddress ): string | null => {
  if (!address) return  null
  const {street, city, house_number} = address
  const cityFormat = city ? `${city},` : ""
  const formattedAddress =`${cityFormat} ${street ?? ""} ${house_number ?? ""}`
  return truncateText(formattedAddress, 40)
}
export default addressFormat

const truncateText = (text: string, maxLength: number): string =>
  text.length > maxLength ? text.slice(0, maxLength) + "..." : text;