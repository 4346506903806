import { FC } from "react";
import Typography from "@mui/material/Typography";

export interface CardTitleProps {
  title: string;
}

const CardTitle: FC<CardTitleProps> = ({ title }) => {
  return (

    <Typography
      gutterBottom
      variant="h6"
      sx={{
        fontWeight: 800,
        fontSize: "20px",
        lineHeight: "24px",
        margin: "0 !important",
        color: "var(--primary-text)",
        "&:first-letter": { textTransform: "uppercase" }
      }}
    >
      {title.slice(0, 1).toUpperCase() + title.slice(1)}
    </Typography>
  );
};
export default CardTitle;