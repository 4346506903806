export default function eventPhoto(path: string | undefined | null): string {
  if (!path) {
    return ""
  }

  return `${import.meta.env.VITE_AVATAR_URL}/v1/events/avatars/${path}`
}

export const avatarUrlDecorator = (path?: string | null):string | undefined=> {
  if (!path) return undefined
  return `${import.meta.env.VITE_AVATAR_URL}/v1/user/avatar/${path}`;
}
export const baseUrl = () => {
  return `${import.meta.env.VITE_API_BASE_URL}`
}
