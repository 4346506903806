import { RefreshTokensApiResponse } from "@app/data/tribeSwaggerApi";
import { deleteApplicationToken, selectRefreshToken, setUserCredentials } from "@app/data/appTokensStore";
import { RootState } from "@app/store";
import baseQuery from "@app/data/jwt/baseRequest";

export async function refreshTokens(api: any, extra: any): Promise<RefreshTokensApiResponse | null> {
  const refreshToken = selectRefreshToken(api.getState() as RootState);
  if (!refreshToken) return null;
  api.dispatch(deleteApplicationToken())

  const refreshResult = await baseQuery(
    {
      url: "/api/v1/auth/token/refresh",
      headers: { Authorization: `Bearer ${refreshToken}` },
    },
    api,
    extra,
  );
  if (refreshResult.error) return null
  if (refreshResult.data) {
    const newTokens = refreshResult.data as RefreshTokensApiResponse;
    api.dispatch(
      setUserCredentials({
        applicationToken: newTokens.access_token,
        refreshToken: newTokens.refresh_token,
        userId: newTokens.user_id,
      }),
    );
    return newTokens;
  }

  return null;
}
