import { FilterType } from "../types";

export function filterToURLSearchParams(filter: FilterType): URLSearchParams {
  const params = new URLSearchParams();

  for (const key in filter) {
    const value = filter[key as keyof FilterType];
    if (Array.isArray(value)) {
      value.forEach(v => {
        if (v!== undefined && v!== null) {
          params.append(key, v.toString())
        }
      });
    } else if (value !== undefined && value !== null) {

      params.append(key, value.toString());
    }
  }
  return params;
}