import { FC, useMemo } from "react";
import { AdaptiveStack } from "@components/PaginationView/AdaptiveStack";
import { useAppSelector } from "@app/hooks";
import { selectTotalElements } from "@shared/feed-pagination/selectors";
import useFeedPaginationState from "@shared/events-feed/hooks/useFeedPagination";
import useResize from "@hooks/useResize";
import { Pagination } from "@mui/material";
import { useSizeOfPagination } from "@pages/fid/hooks/useSizeOfPagination";
import { useLocation } from "react-router-dom";

const PaginationView: FC = () => {
  const totalPages: number  = useAppSelector(selectTotalElements)
  const {page, setPage, size} = useFeedPaginationState()
  const sizeOfPagination = useSizeOfPagination()
  const { pathname } = useLocation()

  const screenWidth = useResize()
  const totalQuantityOfPages = useMemo(() => {
    return totalPages && Math.floor(totalPages / size)
  }, [screenWidth, page, totalPages])
  const handleChangePage = (_: any, num: number) => {

    if (num === page) return
    setPage(num-1)
  }
  const viewPage = ():number =>page+1
  if (pathname === "/" && totalPages && totalQuantityOfPages){
    return <AdaptiveStack>
      <Pagination
        count={totalQuantityOfPages}
        page={viewPage()}
        variant="outlined"
        shape="rounded"
        size={sizeOfPagination}
        boundaryCount={1}
        siblingCount={3}
        onChange={handleChangePage}
      />
    </AdaptiveStack>
  }else {
    return null
  }

}
export default PaginationView