import { FC, useEffect, useState } from "react";
import { Snackbar, Stack } from "@mui/material";

import { EventDto } from "@shared/events-feed/slice/types";
import DialogLayoutStyled from "../Styled/DialogLayout";
import CloseIconStyled from "../Styled/CloseIcon";
import DialogTitleStyled from "../Styled/DialogTitleStyled";
import ModalCard from "../../StyledCard/ModalThumbCard";
import LinkLabelStyled from "../../StyledCard/components/LinkLabel";

import Telegram from "../../../assets/svg/socials-icons/Telegram.svg?react";
import Skype from "../../../assets/svg/socials-icons/Skype.svg?react";
import VK from "../../../assets/svg/socials-icons/Vkontakte.svg?react";
import WatsApp from "../../../assets/svg/socials-icons/WhatsApp.svg?react";
import { lockScroll, unlockScroll } from "@utils/scroll.utils";


export interface SharedEventDialogProps {
  open: boolean;
  onClose?: () => void;
  info: EventDto;
}

const getCurrentDomain = (url: number): string => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}/#/${url}`;
};

const SharedEventDialog: FC<SharedEventDialogProps> = ({ open, onClose, info }) => {
  const handleClose = async () => {
    if (onClose) {
      onClose();
      setShowSnackbar(false);
    }
  };



  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const copyHandler = () => {
    navigator.clipboard.writeText(getCurrentDomain(info.eventId)).then(() => {
      setShowSnackbar(true);
    });
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return <DialogLayoutStyled
    open={open}
    onClose={handleClose}>
    <CloseIconStyled onClick={handleClose} />
    <Stack gap="16px" padding="0px 16px" direction="column">
      <DialogTitleStyled text="Поделиться" />
      <ModalCard eventInfo={info} />
      <LinkLabelStyled onClick={copyHandler} text={getCurrentDomain(info.eventId)} />
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        margin="16px auto"
      >
        <VK />
        <WatsApp />
        <Skype />
        <Telegram />
      </Stack>
    </Stack>
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={showSnackbar}
      autoHideDuration={1000}
      message="Сылка скопирована"
      onClose={closeSnackbar}
    />
  </DialogLayoutStyled>;
};
export default SharedEventDialog;