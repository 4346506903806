import { Box } from "@mui/material";
import {  useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import FilterIcon from "../../assets/svg/filter-pack/filter.svg?react";
import CategoryTabs from "../../components/CategoryTabs";
import Filtered from "../../components/filtered";
import { CATEGORY_SHOULD_DISPLAYED } from "@/consts";
import useResize from "../../hooks/useResize";
import {
  btnCreateEventCont,
  btnsTabsCont,
  feedCont,
  fidContent,
  fidWrapper,
  filterForMapPage,
  filterIconCont,
  tabsContainer
} from "./fidStyles";
import s from "./styles.module.css";
import EventProvider from "../../shared/events-feed/event-provider";
import PaginationView from "@components/PaginationView";
import CreateEventButton from "@components/CreateEventButton";

const isActiveButton = (v: { isActive: boolean }) =>
  v.isActive ? `${s.button_feed} ${s.active}` : s.button_feed

const Fid = () => {
   const { pathname } = useLocation()
  const screenWidth = useResize()
  const [isFilterShow, setIsFilterShow] = useState(false)
  const isLandscapeFeed = ():boolean => (pathname !== "/map" && screenWidth >= 768)
  const isDesktopMap = (): boolean => (pathname === "/map" && screenWidth >= 1033)


  return (
    <div style={fidWrapper}>
      <div style={fidContent}>
        {CATEGORY_SHOULD_DISPLAYED.includes(pathname) && <CategoryTabs />}

        <Box sx={feedCont}>
          <Box sx={btnsTabsCont}>
            <Box sx={tabsContainer}>
              <NavLink to={"/"} className={isActiveButton}>
                Лента
              </NavLink>

              <NavLink to={"/map"} className={isActiveButton}>
                На карте
              </NavLink>
            </Box>
          </Box>

          <Box sx={btnCreateEventCont}>
            {pathname === "/map" &&
              screenWidth >= 768 &&
              screenWidth <= 1033 && (
                <>
                  <Box
                    onClick={() => setIsFilterShow((prev) => !prev)}
                    sx={{
                      ...filterIconCont,
                      background: isFilterShow
                        ? "var(--btns-selected)"
                        : "transparent",
                    }}
                  >
                    <FilterIcon
                      stroke={`var(--secondary-text)`}
                      style={{ position: "absolute" }}
                    />
                  </Box>

                  <Filtered
                    isOpenFilter={isFilterShow}
                    setIsOpenFilter={setIsFilterShow}
                    extraStyles={{
                      ...filterForMapPage,
                      right: isFilterShow ? 25 : -400,
                    }}
                  />
                </>
              )}

            {isDesktopMap() && (
              <CreateEventButton/>
            )}

            {isLandscapeFeed() && (
              <CreateEventButton/>
            )}
          </Box>
        </Box>
        <EventProvider>
          <Box sx={{ ...feedCont, paddingTop: "0 !important" }}>
            {isDesktopMap() && <Filtered />}
            {isLandscapeFeed() && <Filtered />}
            <Outlet />
          </Box>
        </EventProvider>
          <PaginationView/>
      </div>
    </div>
  )
}

export default Fid
