import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { styled } from "@mui/material";

const WalletIconStyled = styled(AccountBalanceWalletIcon)(()=>({
  position: "absolute",
  color: 'green',
  marginLeft: "10px",
  scale: "1.2",
  bottom: 10
  //transform: "translateY(90px)"
}))
export default WalletIconStyled