import { TokenStorageModel } from "./appTokensStore"
import tokenStorage, { TokenStorage } from "@app/data/TokenStorProxy";

export interface PersistentStorage {
  getData(): TokenStorageModel
  setData(newData: TokenStorageModel): void
  clean(): void
}
export const persistentStorage = (): PersistentStorage => {
  return new PlatformStorage(tokenStorage)
}

class PlatformStorage implements PersistentStorage {
  constructor(
    private readonly storage: TokenStorage) {
  }


  private defaultData: TokenStorageModel = {}

  getData(): TokenStorageModel {
    const userId = this.storage.userId
    const applicationToken = this.storage.applicationToken
    const refreshToken = this.storage.refreshToken
    return {applicationToken, userId, refreshToken}
  }
  setData(newData: TokenStorageModel): void {
    this.storage.applicationToken = newData.applicationToken
    this.storage.userId = newData.userId
    this.storage.refreshToken= newData.refreshToken
  }
  clean(): void {
    this.storage.cleanStorage()
  }
}
