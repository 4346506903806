import { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";

export interface LayoutProps {
  align?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "start" | "end" | "left" | "right";
  gap?: number;
}

export const Row: FC<PropsWithChildren<LayoutProps>> = ({
                                                          children,
                                                          align,
                                                          justifyContent,
                                                          gap
                                                        }) => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: justifyContent ?? "space-between",
      alignItems: align ?? "center",
      gap: `${gap ?? 1}px}`
    }}>
      {children}
    </Box>
  );
};

export const Column: FC<PropsWithChildren<LayoutProps>> = ({ children, align, justifyContent }) => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: justifyContent ?? "space-between",
      alignItems: align ?? "center"
    }}>
      {children}
    </Box>
  );
};