export interface TokenStorage {
  applicationToken?: string;
  refreshToken?: string;
  userId?: number;
  cleanStorage(): void;
}

// Маппинг ключей из интерфейса на localStorage
const localStorageKeys: Record<keyof Omit<TokenStorage, 'cleanStorage'>, string> = {
  applicationToken: 'applicationToken',
  refreshToken: 'refreshToken',
  userId: 'userId',
};

// Прокси для работы с localStorage
const tokenStorage: TokenStorage = new Proxy(
  {
    cleanStorage() {
      (Object.values(localStorageKeys) as string[]).forEach((key) =>
        localStorage.removeItem(key)
      );
    },
  },
  {
    get(target, prop: keyof TokenStorage) {
      if (prop === 'cleanStorage') return target[prop];

      const key = localStorageKeys[prop as keyof typeof localStorageKeys];
      if (key) {
        const value = localStorage.getItem(key);
        if (prop === 'userId') {
          return value ? Number(value) : undefined; // Парсим userId как число
        }
        return value ?? undefined; // Возвращаем значение или undefined
      }

      return undefined;
    },

    set(target, prop: keyof TokenStorage, value) {
      const key = localStorageKeys[prop as keyof typeof localStorageKeys];
      if (key) {
        if (value !== undefined && value !== null) {
          localStorage.setItem(key, String(value));
        } else {
          localStorage.removeItem(key); // Удаляем ключ при null или undefined
        }
        return true;
      }
      return false;
    },
  }
);

export default tokenStorage;
