import { FC } from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { getDayAndMonth } from "./getDayAndMonth";

export interface CardBadgeProps {
 time?: string
}
const CardBadge:FC<CardBadgeProps> = ({time}) => {
  if (!time) return null
  const  formDate = getDayAndMonth(time)
  if (!formDate) return null
  const [day, month] = formDate
  return (<Stack component="div"
                 sx={{
                   marginLeft: "10px",
                   transform: 'translateY(-2px)', // Сдвиг на 4px вверх
                 }}
                 direction="column">
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "48px",
      height: "16px",
      borderTopLeftRadius: "2px",
      borderTopRightRadius: "2px",
      backgroundColor: "var(--secondary-text)",
      color: `var(--white)`,
    }}>
      <p style={{ fontSize: "10px", fontWeight: 700}}>{month}</p>
    </Box>
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "48px",
      height: "32px",
      backgroundColor: `var(--like-main-bg)`,
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      color: `var(--calendar-main-text)`,
      borderStyle: "solid",
      borderColor: `var(--calendar-fav-bg)`,
      borderWidth: "2px",
    }}>
      <p style={{ fontSize: "24px", fontWeight: 900}}>{day}</p>
    </Box>
  </Stack>)
}
export default CardBadge