// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3C2blvhrFPO6l-tnaQUnisohLEpmGfLw",
  authDomain: "tribe-test-40a76.firebaseapp.com",
  projectId: "tribe-test-40a76",
  storageBucket: "tribe-test-40a76.firebasestorage.app",
  messagingSenderId: "381983393501",
  appId: "1:381983393501:web:c50e26adcf7ff4249e3d34",
  measurementId: "G-EBMPE75SJD"
}

export default firebaseConfig