import { Env } from "@utils/env.utils";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "@app/store";
import { selectApplicationToken } from "@app/data/appTokensStore";

const baseQuery = fetchBaseQuery({
  baseUrl: Env.baseUrl,
  jsonContentType: "application/json",
  prepareHeaders: (headers: Headers, { getState }) => {
    if (!Env.baseUrl) {
      console.error("Base URL is not defined in Env.baseUrl");
    }

    const token = selectApplicationToken(getState() as RootState);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});
export default baseQuery;