import { Typography } from "@mui/material";
import { FC } from "react";
export interface LinkLabelProps {
  text: string;
  onClick?: () => void;
}
const LinkLabelStyled: FC<LinkLabelProps> = ({ text, onClick })  => {
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  }
  return <Typography margin="auto" marginTop="16px" align="center"  onClick={onClickHandler}  sx={{
    backgroundColor: "var(--inputs-bg)",
    borderRadius: "8px",
    padding: "6px 16px",
    "&:hover": {
      cursor: "pointer",
    }

  }}>
    {text}
  </Typography>
}

export default LinkLabelStyled;