import { styled } from "@mui/material";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

export  const StyledCard = styled(Card)(({theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  position: "relative",
  borderRadius: "16px",
  padding: "8px",
  backgroundColor: `var(--surface-bg)`,
  width: "100%",
}))

export const StyledDivider = styled(Divider)(({theme}) => ({
  borderColor: `var(--secondary-text)`,
  marginTop: "8px",
  marginBottom: "8px",
}))

export const StyledCardMedia = styled(CardMedia)(({theme}) => ({
  position: "relative",
  padding: "0px 0px 40% 0px",
  marginBottom: "2px",
  borderRadius: "8px",
  backgroundColor: "var(--inputs-bg)",
  img: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}))

export const FeedCardContent = styled(CardContent)(({theme}) => ({
  padding: "2px"
}))