import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { CSSProperties } from "react"
import "/src/theme/colors.css"

export const cardAvatarCont: SxProps<Theme> = {
  display: "flex",
  gap: "8px",
  width: "100%",
  alignItems: "center",
  mb: "12px",
  /* minWidth: "100%" */
}


export const cardAvatarIcons: CSSProperties = {
  width: "24px",
  height: "24px",
  fontSize: "12px",
}