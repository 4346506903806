import { SxProps, Theme } from "@mui/material"

export const footerCont: SxProps<Theme> = {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  maxWidth: "100vw",
  placeItems: "center",
  backgroundColor: "transparent",
  "@media (min-width: 768px)": {
    display: "none",
  },
}

export const footerNav: SxProps<Theme> = {
  display: "flex",
  height: "50px",
  width: "100%",
  justifyContent: "space-around",
  alignItems: "center",
  backgroundColor: "var(--footer-bg)",
  borderRadius: "8px",
  padding: "0 8px",
  borderTop: "3.5px solid var(--main-bg)",
  "@media (min-width: 638px)": {
    padding: "0px 32px",
  },
}

export const footerNavItem: SxProps<Theme> = {
  position: "relative",
  padding: 0,
  width: "56px",
  height: "50px",
  minWidth: "48px",
  zIndex: "1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  color: "var(--footer-icons)",
  "&.Mui-selected:not(:nth-of-type(2))": {
    //исправить на 3, когда будет 5 иконок в меню
    color: "#BDC2FF",
    svg: {
      background: "#492e7d",
      boxShadow: "0px 4px 28px 0px #5837F380, 0px 0px 20px 0px #9747FF",
    },
  },
  "&:nth-of-type(2)": {
    //исправить на 3, когда будет 5 иконок в меню
    transform: "translateY(-24px)",
  },
  "&:nth-of-type(2) svg": {
    //исправить на 3, когда будет 5 иконок в меню
    background: "var(--profile-ntfc-text)",
    fill: "#fef7ff",
    borderRadius: "50%",
    width: "48px",
    height: "48px",
    padding: "8px",
    border: "3.5px solid var(--main-bg)",
  },
}
