import { FC, useEffect } from "react";
import { EventDto } from "@shared/events-feed/slice/types";

export interface NativeShareDialogProps {
  open: boolean;
  onClose?: () => void;
  info: EventDto;
}
const getCurrentDomain = (url: number): string => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}/#/${url}`;
};
const NativeShareDialog: FC<NativeShareDialogProps> = ({info, open}) => {
  const handleShare = async () => {
    if(navigator.share) {
      try{
        await navigator.share({
          title: info.eventName,
          text: info.description,
          url: getCurrentDomain(info.eventId)
        })
      }catch (error){
        console.error(error)
      }
    }
  }
  useEffect(() => {
    if(open) {
      handleShare()
    }
  }, [open]);
  return null;
}
export default NativeShareDialog