import { FC } from "react";
import Typography from "@mui/material/Typography";

export interface CardDescriptionProps {
  text?: string;
  maxLength?: number
}
const truncateText = (text: string, maxLength: number): string =>
  text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

const CardDescription: FC<CardDescriptionProps> = ({text, maxLength}) => {
  if (!text) return null
  return (<Typography sx={{
    marginBottom: "0px"
  }}  variant="body2" color={`var(--secondary-text)`}>
    {truncateText(text, maxLength ?? 125)}
  </Typography>)
}
export default CardDescription