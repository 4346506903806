import { FC } from "react"
import IconButton from "@mui/material/IconButton"
import ShareIconCard from "/src/assets/svg/card-icons-pack/share-icon.svg?react"

interface ShareButtonProps {
  onClick?: () => void
}
const  ShareButton: FC<ShareButtonProps> = ({onClick}) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }
  return <IconButton onClick={handleClick}>
    <ShareIconCard fill={"var(--secondary-text)"} />
  </IconButton>
}
export default ShareButton