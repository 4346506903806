import { Dialog, DialogProps, styled } from "@mui/material"
import { FC } from "react"
import Paper from "@mui/material/Paper"
import { SlideUp } from "../../../Transition"

const DialogLayout = styled(Dialog)(({ theme }) => ({
  maxWidth: "440px",
  margin: "0 auto",
}))

const DialogLayoutStyled: FC<DialogProps> = (props) => {
  return (
    <DialogLayout
      {...props}
      fullWidth
      PaperComponent={(props, deprecatedLegacyContext) => {
        return (
          <Paper
            sx={{
              borderRadius: "16px",
              backgroundColor: "var(--main-bg)",
              boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
              fontFamily: "Roboto",
              fontWight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "var(--secondary-text)",
              padding: "8px 8px 32px",
              letterSpacing: "0.5px",
              textAlign: "left",
              display: "grid",
              gridTemplateRows: "24px 1fr",
              width: "100%",
              height: "100%",
              maxHeight: "734px",
              maxWidth: "375px",
            }}
            elevation={4}
            {...props}
          />
        )
      }}
      TransitionComponent={SlideUp}
    />
  )
}
export default DialogLayoutStyled
