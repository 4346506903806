import { Stack, styled } from "@mui/material";

export const AdaptiveStack = styled(Stack)(()=>({
  alignItems: "center",
  marginBottom: "16px",
  padding: "0 5px",

  "@media (min-width: 638px)": {
    padding: "0px 32px",
  },

  "& .MuiPagination-ul": {
    display: "flex",
    justifyContent: "center",
  },

  "& .MuiPaginationItem-root": {
    marginBottom: "5px",
    background: "transparent",
    border: "1px solid var(--borders)",
    overflow: "hidden",
    color: "var(--primary-text)",

    "&:hover": {
      background: "var(--btns-hover)",
    },

    "&.Mui-selected": {
      background: "var(--btns-selected) !important",
    },
  },
}))